import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/storefront/src/assets/images/home_hero.webp");
;
import(/* webpackMode: "eager", webpackExports: ["ReactComponent"] */ "/vercel/path0/apps/storefront/src/assets/svg/logo_sign.svg");
;
import(/* webpackMode: "eager", webpackExports: ["NextImage"] */ "/vercel/path0/apps/storefront/src/components/NextImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/vercel/path0/apps/storefront/src/components/ProductCard/components/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Name"] */ "/vercel/path0/apps/storefront/src/components/ProductCard/components/Name.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Price"] */ "/vercel/path0/apps/storefront/src/components/ProductCard/components/Price.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/vercel/path0/apps/storefront/src/components/ProductCarousel/components/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/storefront/src/components/VariantCard/VariantCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.19.1_next@14.3.0-canary.87_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@_e3xbgpjjn56harlavteur7prom/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next@14.2.1-canary.7_@babel+core@7.25.2_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
