"use client";

import { useTranslations } from "next-intl";

import { useLocalizedPaths } from "@projectluna/lib/paths/client";

import { Badge } from "@/components/Badge";
import { HoverImage, type HoverImageProps } from "@/components/HoverImage";
import { Link } from "@/components/Link";
import { PriceRange } from "@/components/PriceRange";
import { CLIENT_CONFIG } from "@/config/client";
import { type ProductVariantCardFragment } from "@/graphql/fragments/generated";
import { type WithCheckout } from "@/lib/checkout/helpers";
import { getProductName, isVariantInStock } from "@/lib/product/helpers";
import { trackActivity } from "@/lib/tracking/handlers";

import { cn } from "@/styles/lib";

export type VariantCardProps = WithCheckout & {
  analyticsName?: string;
  imageProps?: Omit<HoverImageProps, "alt" | "media">;
  variant: ProductVariantCardFragment;
};

export const VariantCard = ({
  variant: { media, product, id, pricing, created, ...rest },
  variant,
  checkout,
  imageProps,
  analyticsName,
}: VariantCardProps) => {
  const name = getProductName(product, rest);
  const paths = useLocalizedPaths();
  const t = useTranslations();

  const createdAt = new Date(created);
  const createdItWithThreshold = new Date(
    new Date(createdAt).setDate(
      createdAt.getDate() + CLIENT_CONFIG.NEW_PRODUCT_BADGE_DAYS
    )
  );

  const isOnSale = pricing?.onSale;
  const isSoldOut = !isVariantInStock(variant, checkout?.lines);
  const today = new Date();
  const inNewProduct = createdItWithThreshold > today;

  const badge = (() => {
    if (isSoldOut) {
      return <Badge backgroundColor="red">{t("product.soldOut")}</Badge>;
    }
    if (isOnSale) {
      <Badge>{t("product.sale")}</Badge>;
    }

    if (inNewProduct) {
      return <Badge backgroundColor="orange">{t("product.new")}</Badge>;
    }
  })();
  const images: HoverImageProps["media"] = media?.length
    ? media
    : product.media
      ? product.media
      : [];
  const href = paths.product.asPath({
    slug: product.slug,
    hash: isSoldOut ? undefined : id,
  });

  const handleClick = () => {
    trackActivity.viewProductFromListing(
      analyticsName || "VariantCard",
      product,
      [variant]
    );
  };

  return (
    <div
      className={cn("card-gap relative grid", {
        "[&_*:not(mark)]:opacity-75": isSoldOut,
      })}
    >
      {badge}

      <Link href={href} onClick={handleClick}>
        <HoverImage
          alt={name}
          media={images}
          {...imageProps}
          className={cn("card-image", imageProps?.className)}
        />
      </Link>

      <div className="pointer-events-[all] truncate font-bold">
        <Link href={href} onClick={handleClick}>
          {name}
        </Link>
      </div>

      <Link href={href} onClick={handleClick}>
        <PriceRange pricing={product.pricing} />
      </Link>
    </div>
  );
};
